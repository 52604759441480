.app-modal-body.is-open {
  overflow-y: hidden;
}

.app-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 32px;
  padding-top: 240px;
  background: rgb(0,0,0,0.75);
  overflow-y: scroll;
  z-index: 1000;
}

.app-modal-overlay.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.app-modal-overlay.ReactModal__Overlay--after-open {
  opacity: 1;
}

.app-modal-overlay.ReactModal__Overlay--before-close {
  opacity: 0;
}